import React from 'react'
import styled from 'styled-components';
import Image from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


const Footer = () => <FooterStyled>
  <StaticQuery
    query={bioQuery}
    render={data =>
      <>
        <Userpic>
          <OutboundLink href="https://robox.dev/">
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={`Maxim Kolesnikov`}
            />
          </OutboundLink>
        </Userpic>
        <Me>
          <p><OutboundLink href="https://robox.dev/">Maxim
            Kolesnikov</OutboundLink>, web developer</p>
          <p>Python, Django, JS, React</p>
        </Me>
      </>
    }/>
</FooterStyled>

const FooterStyled = styled.footer`
  display: flex;
  flex-wrap: nowrap;
  color: #828282;
  font-size: 15px;
  p {
    margin: 0;
  }
`;

const Userpic = styled.div`
  margin-right: 16px;
`;


const Me = styled.div`
`;

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Footer
