import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Header = ({ siteTitle, indexPage }) => (
  <HeaderStyled>
    <SiteTitleStyled>
      {indexPage ?
        siteTitle
        : <Link
          to="/"
        >
          {siteTitle}
        </Link>
      }
    </SiteTitleStyled>
    <Links>
      <HeaderLink><OutboundLink href="https://twitter.com/okolo_dev">Twitter</OutboundLink></HeaderLink>
      <HeaderLink><OutboundLink href="https://t.me/okolodev">Telegram</OutboundLink></HeaderLink>
    </Links>
  </HeaderStyled>
);

const HeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 16px 0 24px;
`;

const SiteTitleStyled = styled.div`
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 0;
  margin-right: 24px;
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`;

const HeaderLink = styled.div`
  :not(:last-child) {
    margin-right: 16px;
  }
`;

export default Header;
