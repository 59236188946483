import React from 'react'

import styled from 'styled-components'
import GlobalStyle from '../components/global-style'
import Footer from '../components/footer'
import Header from '../components/header'

const Layout = ({ title, children, indexPage = false }) =>
  <>
    <SiteLayout>
      <Header siteTitle={title} indexPage={indexPage} />
      {indexPage && <Footer />}
      <Content>
        {children}
      </Content>
      {!indexPage && <Footer />}
    </SiteLayout>
    <GlobalStyle />
  </>

const Content = styled.div`
  flex: 1;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 24px;
`;

const SiteLayout = styled.div`
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 30px);
  padding: 15px;
`;


export default Layout
