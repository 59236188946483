import { createGlobalStyle } from 'styled-components'

export const backgroundColor = '#151515';
export const backgroundColorLight = '#fff';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&subset=cyrillic');
  
  body {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5rem;
    margin: 0;
    
    color: #000;
    background-color: ${backgroundColorLight};
    
    // @media (prefers-color-scheme: dark) {
    //   color: #d4d4d4;
    //   background-color: ${backgroundColor};
    // }
  }
  a {
    color: #2a7ae2;
    text-decoration: none;
    box-shadow: none;
    :hover {
      text-decoration: underline;
    }
  }
  code {
    background-color: rgba(27,31,35,.05);
    border-radius: 3px;
    font-size: 85%;
    margin: 0;
    padding: .2em .4em;
  }
  p {
    padding: 0;
    margin: 0 0 15px;
  }
  ul {
    padding-inline-start: 24px;
    list-style-type: circle;
    li {
      margin: 0 0 8px;
    }
  }
  h1 {
    line-height: 1em;
  }
  
`

export default GlobalStyle;
